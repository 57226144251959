.sidebar {
    height: 100%;
    width: 240px;
    /* position: fixed; */
    z-index: 1;
    top: 0;
    left: 0;
    background-color: #FFFFFF;
    overflow-x: hidden;
    padding-top: 18px;
    padding-left: 24px;
    padding-right: 24px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.16);
}

.userBtn {
    position: absolute;
    display: inline-block;
    bottom: 40px;
}

.logo {
    display: flex;
    align-items: center;
    font-size: 12px
}

.centered {
    display: flex;
    align-items: center;
}

.avatar {
    border-radius: 50%;
    height: 40px;
    width: 40px;
}

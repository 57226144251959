.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 12px 16px;
  grid-template-areas:
    "scorecard trend"
    "topics drilldown";
  height: 568px;
}
.grid-container-feed {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 12px 16px;
  grid-template-areas:
    "feed side"
    "feed side";
  height: 568px;
}
.topic-feed { grid-area: feed; }
.topic-scorecard { grid-area: scorecard; }
.topic-trend { grid-area: trend; }
.topic-topics { grid-area: topics; }
.topic-drilldown { grid-area: drilldown; }

.topic-card {
  background: #FFFFFF;
  border-radius: 5px;
  padding: 16px;
  height: 100%;
  color: #707070;
}

.topic-card.feed {
  padding-left: 0;
  padding-right: 0;
  display: flex;
  flex-direction: column;
}

.t-feed {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
}

.t-controls {
  padding: 12px 24px;
  display: flex;
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-color: #DBDBDB;
  align-items: center;
  justify-content: space-between;
}

.t-controls input {
  border: 0;
  font-size: 12px;
  border-bottom: 1px solid transparent;
  width: 240px;
}

.t-controls input:focus {
  outline: 0;
  border-bottom: 1px solid #007bff;
}

.t-controls_button {
  cursor: pointer;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.t-controls_button:hover, .t-controls_dropdown-toggle:hover {
  opacity: 0.8;
}

.t-list {
  flex: 1;
  overflow-y: scroll;
}

.t-comment {
  padding: 12px 24px;
  border-bottom: 1px solid #DBDBDB;
  display: flex;
}

.t-score {
  font-size: 12px;
  line-height: 16px;
  font-weight: bold;
  color: #90282B;
  width: 24px;
}

.t-score._7, .t-score._8 {
  color: #707070;
}

.t-score._9, .t-score._10 {
  color: #279B4B;
}

.t-comment_text {
  color: #333333;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 8px;
}

.t-tag {
  padding: 4px 8px;
  background-color: #F0F0F0;
  border-radius: 4px;
  margin-right: 12px;
  font-size: 10px;
  line-height: 16px;
  text-transform: capitalize;
  font-weight: bold;
}

.t-tag.POSITIVE {
  background-color: rgba(65, 232, 117, 0.6);
  color: #279B4B;
}

.t-tag.NEGATIVE {
  background-color: rgba(232, 65, 71, 0.6);
  color: white;
}

.t-meta {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 10px;
  line-height: 12px;
  margin-top: 12px;
}

.t-controls_dropdown {
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.t-controls_dropdown .dropdown-item {
  position: relative;
  cursor: pointer;
}

.t-controls_dropdown .dropdown-item svg {
  position: absolute;
  top: 12px;
  left: 8px;
}

.t-controls_dropdown-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  cursor: pointer;
}

.t-controls_dropdown-toggle::after {
  display: none;
}
.menu {
    margin-top: 64px;
}

.list {
    list-style-type: none;
    padding-left: 0;
}

.item {
    display: flex;
    align-items: center;
    margin-top: 16px;
}

.item.sub {
  margin-top: 8px;
  font-size: 12px;
}

.itemImage {
    fill: #333;
    margin-right: 24px;
}

.itemText {
    font-size: 16px;
    color: #333333;
    font-weight: 500;
}

.item.sub > .itemText {
  font-size: 12px;
  line-height: 16px;
  padding-left: 40px;
  color: #707070;
}

.menuItemSelected .itemText  {
    color: #FC4B05 !important;
    font-weight: bold;
}

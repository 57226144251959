.CalendarDay__selected_span {
    background: #0299EB;
    color: white; 
    border: 1px solid #0299EB
}
.CalendarDay__selected {
    background: #0288D1;
    color: white;
}
.CalendarDay__selected:hover {
    background: #02679E;
    color: white;
}
.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
    background: #02679E;
    color: white;
}

.DateInput_input {
    font-size: 1rem;
    padding: 5px 5px;
}

.DateRangePicker {
    min-height: 38px;
    margin-left: 16px;
}

.CalendarDay__selected_span:active, .CalendarDay__selected_span:hover {
  background-color: #02679E;
}
.navBar {
    display: flex;
    padding: 12px 24px;
    flex-flow: column wrap;
    background-color: white;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
}

.rowWrap {
    display: flex;
    flex-flow: row wrap;
    margin-bottom: 12px;
    align-content: center;
    justify-content: baseline,
}

.title {
    font-size: 12px;
    color: #707070;
}

.navbar_menu {
    margin-bottom: 0px;
    align-self: center;
    list-style-type: none;
    padding-left: 0;
    color: #333333;
    display: flex;
}

.dateFilterContainer {
    display: flex;
    margin-left: auto;
    margin-right: 16px;
    padding-right: 16px;
}

.navbar_menu > li {
    float: left;
    display: block;
    text-align: center;
    margin-right: 16px;
    font-size: 16px;
    padding-top: 0px;
}

.navbar_menu span {
    fontSize: 16px;
    margin-top: 0px;
    color: #333;
}

.navBarSelected {
    color: #0288D1;
    border-bottom: 2px #0288D1 solid;
    width: 100%;
    height: 40px;
}

.navBarSelected > span {
    color: #0288D1;
}
